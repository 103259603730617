<template>
	<AtomsModal @close="closePopup" class="success-modal">
		<img class="success-img" src="/assets/images/png/success/vibes.png" alt="">
		<h2 class="success-title">{{ $t('pay_success.title') }}</h2>
		<p class="success-description" v-html="$t('pay_success.text', ['<span>', '</span>'])"></p>
		<div class="button-box">
			<button @click="closePopup" class="btn btn-secondary">{{ $t('interface.close') }}</button>
			<NuxtLinkLocale @click="closePopup" :to="linkTo === 'profile' ? '/profile' : '/products'" class="btn btn-light">{{ linkTo === 'profile' ? $t('pay_success.ready_for_shopping') : $t('pay_success.catalogue') }}</NuxtLinkLocale>
		</div>
	</AtomsModal>
</template>

<script setup lang="ts">
const { closePopup, linkTo } = useSuccessModal()
</script>

<style lang="scss" scoped>
.success-modal {
	:deep(.modal-content) {
		max-width: 429px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 24px;
		box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
		padding: 18px 40px 40px;
	}

	.btn {
		width: fit-content;
		font-weight: 700;
		font-size: 16px;
		line-height: 80%;
		letter-spacing: -0.03em;
		padding: 12px 30px 10px;
		flex: 1;
	}
}

.success-img {
	width: 128px;
	height: 128px;
}

.success-title {
	font-weight: 700;
	font-size: 28px;
	line-height: 80%;
	letter-spacing: -0.03em;
	color: #1d254a;
	margin-top: 24px;
}

.success-description {
	font-weight: 600;
	font-size: 18px;
	line-height: 130%;
	letter-spacing: -0.03em;
	color: #1d254a;
	margin-top: 12px;

	:deep(span) {
		color: #da4727;
	}
}

.button-box {
	display: flex;
	gap: 12px;
	margin-top: 24px;
}

@media (max-width:1023px) {
	.success-modal {
		:deep(.modal-content) {
			padding: 18px 16px 16px;
			max-width: 344px
		}

		.btn {
			margin-top: 18px;
			padding: 9px 20px;
		}
	}

	.success-img {
		width: 97px;
		height: 97px;
	}

	.success-title {
		font-size: 24px;
		margin-top: 18px;
	}

}
</style>
